import Vue from 'vue'
import router from './router'
import FullScreenVUE from './FullScreen.vue'
import { BootstrapVue } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)

Vue.config.productionTip = true
//Vue.use(VueRouter);

document.title = '123';

new Vue({
  router,
  beforeCreate() {
    console.log("x");
  },
  created() {
    console.log('ok soweit')
  },
  render: h => h(FullScreenVUE),
}).$mount('#app_content');

