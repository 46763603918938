import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

//import skellet1 from './components/skellet1.vue'

const routes = [
    { path:'/'}
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;