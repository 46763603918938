<template>
  <div id="app" v-bind:class="classObject">
    <img alt="Vue logo" src="./assets/logo.png" style="height:30px; width:30px">

    <router-view></router-view>
    <router-link to="/foo">Go to Foo</router-link> <router-link to="/bar">Go to Bar</router-link>

    <HelloWorld2VUE msg="Testing that"/>
  </div>
</template>

<script>
//import HelloWorld2VUE from './components/HelloWorld.vue'
import skellet1 from './components/skellet1.vue'

export default {
  name: 'appasd',
  components: {
    HelloWorld2VUE:skellet1
  },
  computed: {
    classObject: function () {
      return {
        'bg-light':true
      }
    }
  },
}

//skellet1.testt = '123'
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height:100vh;
}
</style>
